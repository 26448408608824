const FOOTER_HEIGHT = 180;
const HEADER_HEIGHT = 80;
const PAGE_MIN_HEIGHT = window.innerHeight - FOOTER_HEIGHT * 2;

export const COLORS = {
  athensGray: "#F2ECF4",
  darkGray: "#282828",
  whisper: "#F9F6FA",
};

export const BACKGROUNDS = {
  primaryBackground: "#FFF",
  secondaryBackground: COLORS.whisper,
};

export const LAYOUTS = {
  bodyStyle: {
    // marginTop: HEADER_HEIGHT,
    minHeight: "100%",
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  mainSectionStyle: {
    backgroundColor: BACKGROUNDS.primaryBackground,
    minHeight: PAGE_MIN_HEIGHT,
    paddingTop: 0,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

export const TEXTS = {
  primaryText: {
    color: "blue",
    fontWeight: "bold",
  },
  primaryTextWhite: {
    color: "white",
    fontWeight: "bold",
  },
  secondaryText: {
    color: "lightblue",
    fontWeight: "normal",
  },
};
