import { API_PATH } from "../lib/utils";
import { getUrlSession } from "../lib/UrlSession";

// Search
const getBusinessSearch = (body, callback) => {
  let url = `${API_PATH()}?qtype=businesssearch&location=${
    body.location
  }&page=${body.page}&categoryid=${body.categoryId}`;
  if (body.attributes) {
    url += `&attributes=${body.attributes}`;
  }

  getUrlSession(url, callback);
};

const getSearchInfo = (callback) => {
  const url = `${API_PATH()}?qtype=searchinfo`;
  getUrlSession(url, callback);
};

export { getBusinessSearch, getSearchInfo };
