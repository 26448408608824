

export const WEB_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
export const API_PATH = (type) => {
  let path = "";
  path = `/services/contentservice.php`;
// console.log(`${WEB_DOMAIN}${path}`);
  return `${WEB_DOMAIN}${path}`;
};