import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Badge, Button, Row, Col, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BodyLayout from "../components/layouts/BodyLayout";
import { BasicButton } from "../components/buttons/Buttons";
import { getBusinessSearch, getSearchInfo } from "../api/ApiManager";
import SearchResultsCard from "../components/cards/SearchResultsCard";
import GoogleMapReact from "google-map-react";
import {
  MARKER_DIAMETER,
  CurrentLocationMarker,
  Marker,
} from "../components/maps/marker";

const MapSection = React.memo((props) => {
  const defaultMapProps = {
    center: { lat: 32.5141901, lng: -84.9986961 },
    zoom: 12.5,
  };

  let center =
    props.center !== null
      ? { lat: props.center.lat, lng: props.center.lng }
      : defaultMapProps.center;

  const Markers = props.data.map((item, index) => {
    const rank = (props.currentPage - 1) * 10 + index + 1;

    return (
      <Marker
        key={item.id}
        lat={item.lat}
        lng={item.lng}
        data={{
          title: `${rank}. ${item.name}`,
        }}
      />
    );
  });

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDQeeIXoTMCKfTZljDzNFf8kd9f3O24L-8" }}
        defaultCenter={defaultMapProps.center}
        center={center}
        defaultZoom={defaultMapProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        hoverDistance={MARKER_DIAMETER / 2}
        options={{
          clickableIcons: false,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {props.userLocation !== null ? (
          <CurrentLocationMarker
            lat={props.userLocation.latitude}
            lng={props.userLocation.longitude}
          />
        ) : null}
        {Markers}
      </GoogleMapReact>
    </div>
  );
});

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultState = {
    attributeGroups: [],
    attributes: [],
    categories: [],
    locations: [],
    selectedAttributes: [],
    selectedCategoryId: parseInt(searchParams.get("categoryId") ?? 0),
    selectedLocation: searchParams.get("location") ?? null,
    searchLocationList: [],
  };

  const [searchResults, setSearchResults] = useState({
    currentPage: parseInt(searchParams.get("page") ?? 0),
    results: {
      businesses: [],
      center: null,
      pages: 0,
      more: false,
    },
  });
  const [searchState, setSearchState] = useState(defaultState);
  const [userLocation, setUserLocation] = useState(null);

  // Handlers
  // Apply Selected Attributes
  const handleApply = () => {
    fetchBusinesses(
      searchState.selectedLocation,
      searchState.selectedCategoryId,
      1,
      searchState.selectedAttributes
    );
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "attribute":
        const attrId = parseInt(e.target.id);

        if (e.target.checked === true) {
          const attr = searchState.attributes.find(
            (item) => item.id === attrId
          );
          setSearchState({
            ...searchState,
            selectedAttributes: [...searchState.selectedAttributes, attr],
          });
        } else {
          removeAttribute(attrId);
        }
        break;
      case "categorySelectMenu":
        setSearchState({
          ...searchState,
          selectedCategoryId: parseInt(e.target.value),
        });
        break;
      case "location":
        if (e.target.value === "" || typeof e.target.value === "undefined") {
          setSearchState({
            ...searchState,
            selectedLocation: null,
            searchLocationList: [],
          });
        } else {
          const locationInput = e.target.value;
          getLocationList(locationInput);
        }
        break;
      default:
        break;
    }
  };

  // Clear Selected Attributes
  const handleClear = () => {
    setSearchState({
      ...searchState,
      ...{ selectedAttributes: [] },
    });
  };

  const handleClick = (e) => {
    const name = e.target.name || e.target.getAttribute("name");
    switch (name) {
      case "attrPill":
        const attrId = parseInt(e.target.id);
        removeAttribute(attrId);
        break;
      default:
        const selectedLocation = e.target.getAttribute("name");
        setSearchState({
          ...searchState,
          selectedLocation: e.target.getAttribute("value"),
          searchLocationList: [],
        });
        document.getElementById("location").value = selectedLocation;
        break;
    }
  };

  const handleGroupSectionExpand = (group) => {
    const updatedGroups = searchState.attributeGroups.map((groupItem) => {
      if (groupItem.group === group) {
        return { ...groupItem, expanded: !groupItem.expanded };
      }
      return groupItem;
    });

    setSearchState({ ...searchState, attributeGroups: updatedGroups });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    fetchBusinesses(
      searchState.selectedLocation,
      searchState.selectedCategoryId,
      selectedPage + 1,
      searchState.selectedAttributes
    );
  };

  // Handle Search when the search button clicked
  const handleSearch = () => {
    fetchBusinesses(
      searchState.selectedLocation,
      searchState.selectedCategoryId,
      1,
      searchState.selectedAttributes
    );
  };

  // Methods
  const fetchBusinesses = (location, categoryID, page, attributes) => {
    if (location === null || location.length === 0) {
      alert("Please enter where you will like to search");
      return;
    }

    let searchBody = {
      categoryId: categoryID,
      location: location,
      page: page,
    };

    // Add selected attribute IDs
    if (attributes !== null && attributes.length > 0) {
      let ids = attributes.map((item) => item.id).join(",");
      searchBody["attributes"] = ids;
    }

    getBusinessSearch(searchBody, (data) => {
      setSearchResults({
        ...searchResults,
        currentPage: page,
        results: data,
      });
    });

    // Update the url's query params
    let queryStrings = `?location=${searchBody.location}&page=${searchBody.page}`;
    queryStrings +=
      searchBody.categoryId !== 0 ? `&categoryId=${searchBody.categoryId}` : "";
    queryStrings +=
      typeof searchBody.attributes !== "undefined"
        ? `&attributes=${searchBody.attributes}`
        : "";
    setSearchParams(queryStrings);
  };

  const getAttributeCheckboxValue = (item) => {
    return searchState.selectedAttributes.includes(item);
  };

  const getLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (position) => {
      const coords = position.coords;

      console.log(`Latitude : ${coords.latitude}`);
      console.log(`Longitude: ${coords.longitude}`);
      setUserLocation(coords);
    };

    const displayErrors = (error) => {
      console.warn(`ERROR(${error.code}): ${error.message}`);
    };

    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(success);
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            success,
            displayErrors,
            options
          );
        }
      });
    }
  };

  const getLocationList = (query) => {
    const locations = searchState.locations
      .map((location) => {
        return location.name;
      })
      .filter((location) =>
        location.toLowerCase().startsWith(query.toLowerCase())
      );

    setSearchState({ ...searchState, searchLocationList: locations });
  };

  const removeAttribute = (id) => {
    const filteredAttributes = searchState.selectedAttributes.filter(
      (item) => item.id !== id
    );

    setSearchState({
      ...searchState,
      selectedAttributes: filteredAttributes,
    });
  };

  // Sections
  const AttributeSection = () => {
    const groupRows = searchState.attributeGroups.map((groupItem) => {
      const attributeItem = groupItem.attributes.map((attr) => {
        return (
          <li
            className="list-group-item"
            key={attr.id}
            style={styles.attributeItem}
          >
            <Form.Check
              type={"checkbox"}
              id={attr.id}
              label={attr.name}
              name={"attribute"}
              onChange={handleChange}
              checked={getAttributeCheckboxValue(attr)}
            />
          </li>
        );
      });

      return (
        <Col key={groupItem.group} md={12} className="mb-2">
          <Row className="mb-1">
            <Col>
              <span style={styles.attributeTitle}>{groupItem.group}</span>
            </Col>
            <Col md="auto" style={{ padding: 0 }}>
              <Button
                onClick={() => handleGroupSectionExpand(groupItem.group)}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={
                    groupItem.expanded
                      ? "fa-solid fa-minus"
                      : "fa-solid fa-plus"
                  }
                  size="1x"
                  color="#000"
                />
              </Button>
            </Col>
          </Row>
          {groupItem.expanded ? (
            <Row>
              <ul className="list-group list-group-vertical-md p-0">
                {attributeItem}
              </ul>
            </Row>
          ) : null}
        </Col>
      );
    });

    return groupRows;
  };

  const CategorySelectMenu = () => {
    const options = searchState.categories.map((category) => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });

    return (
      <div>
        <select
          className="p-2 w-100"
          onChange={handleChange}
          value={searchState.selectedCategoryId}
          name="categorySelectMenu"
          id="categorySelectMenu"
        >
          <option value="default">Select a Category</option>
          {options}
        </select>
      </div>
    );
  };

  const LocationSelections = () => {
    const locationSelection = searchState.searchLocationList.map(
      (selection, idx) => {
        return (
          <li
            key={selection}
            name={selection}
            value={selection}
            onClick={handleClick}
            style={styles.selectionItem}
          >
            {selection}
          </li>
        );
      }
    );

    return (
      <div>
        <ul style={styles.selectionSection}>{locationSelection}</ul>
      </div>
    );
  };

  const PillSection = () => {
    const pills = searchState.selectedAttributes.map((item) => {
      return (
        <Button
          key={item.id}
          name="attrPill"
          id={item.id}
          variant="primary"
          onClick={handleClick}
          style={{ marginBottom: 10, marginRight: 10 }}
        >
          {item.name}{" "}
          <Badge name="attrPill" id={item.id} bg="secondary">
            x
          </Badge>
        </Button>
      );
    });

    return (
      <Col className="mb-4 d-flex flex-wrap align-self-start">{pills}</Col>
    );
  };

  const SearchResultsSection = () => {
    if (searchResults.results.businesses.length === 0) {
      return null;
    } else {
      const searchItems = searchResults.results.businesses.map(
        (result, index) => {
          const rank = (searchResults.currentPage - 1) * 10 + index + 1;
          return <SearchResultsCard key={rank} rank={rank} data={result} />;
        }
      );

      return (
        <div style={styles.searchResultsSection}>
          {searchItems}
          {searchResults.results.pages > 1 ? (
            <div className="mt-4">
              <ReactPaginate
                breakLabel="..."
                nextLabel="→"
                onPageChange={handlePageClick}
                pageCount={searchResults.results.pages}
                previousLabel="←"
                forcePage={searchResults.currentPage - 1}
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
          ) : null}
        </div>
      );
    }
  };

  // Hooks
  useEffect(() => {
    getSearchInfo((info) => {
      const attributes = searchParams.get("attributes") ?? [];
      const attributeIds =
        attributes.length > 0
          ? searchParams
              .get("attributes")
              .split(",")
              .map((item) => parseInt(item))
          : [];
      const selectedAttributes = info.attributes.filter((item) =>
        attributeIds.includes(item.id)
      );

      if (searchState !== defaultState || searchParams.get("fromHome") === "true") {
        fetchBusinesses(
          searchState.selectedLocation,
          searchState.selectedCategoryId,
          searchResults.currentPage,
          selectedAttributes
        );
      }

      // Create Attribute Groups
      const allGroups = info.attributes.map((attr) => {
        return attr.group;
      });
      const groups = [...new Set(allGroups)];

      const groupObjects = groups.map((group) => {
        let attrs = info.attributes.filter((attr) => {
          return attr.group === group;
        });

        return {
          group: group,
          expanded: true,
          attributes: attrs,
        };
      });

      setSearchState({
        ...searchState,
        attributeGroups: groupObjects,
        attributes: info.attributes,
        categories: info.categories,
        locations: info.locations,
        selectedAttributes: selectedAttributes,
      });
    });

    getLocation();
  }, []);

  // console.log("STATE", searchState);

  return (
    <div>
      <BrowserView>
        <BodyLayout>
          <Row>
            <Form style={styles.form}>
              <Form.Group>
                <Row>
                  <Col md="auto">
                    <Link
                      to="/"
                      style={{
                        color: "blue",
                        fontSize: 40,
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Hydrogen
                    </Link>
                  </Col>
                  <Col md="auto" className="d-flex align-items-center">
                    <CategorySelectMenu />
                  </Col>
                  <Col md={4} style={{ marginTop: 11 }}>
                    <Row>
                      <Form.Control
                        type="text"
                        placeholder="city, state or zip"
                        id="location"
                        name="location"
                        onChange={handleChange}
                        defaultValue={searchState.selectedLocation}
                        autoComplete="off"
                      />
                    </Row>
                    <Row style={styles.locationSelectionSection}>
                      <LocationSelections />
                    </Row>
                  </Col>
                  <Col md="auto" style={{ marginTop: 11, marginLeft: -55 }}>
                    <Button onClick={handleSearch}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-magnifying-glass"
                        size="1x"
                      />
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Row>
          <Row>
            <PillSection />
          </Row>
          <Row>
            <Col md="auto" style={styles.filterCol}>
              <Row>
                <AttributeSection />
              </Row>
              <Row>
                <Col>
                  <BasicButton text={"Apply"} onClick={handleApply} />
                  <BasicButton text={"Clear"} onClick={handleClear} />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <SearchResultsSection />
            </Col>
            <Col>
              <MapSection
                center={searchResults.results.center}
                currentPage={searchResults.currentPage}
                data={searchResults.results.businesses}
                userLocation={userLocation}
              />
            </Col>
          </Row>
        </BodyLayout>
      </BrowserView>
      <MobileView></MobileView>
    </div>
  );
};

export default Search;

const styles = {
  attributeTitle: {
    fontWeight: "bold",
    fontSize: 18,
    display: "flex",
    paddingTop: 10,
  },
  attributeItem: {
    border: "none",
    textAlign: "left",
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 10,
    paddingRight: 10,
  },
  filterCol: {
    width: 280,
  },
  form: {
    width: "100%",
    textAlign: "left",
  },
  locationSelectionSection: {
    marginTop: -1,
    marginLeft: -20,
    height: 0,
  },
  searchResultsSection: {
    width: 400,
    // height: "100vh",
    // overflowY: "scroll",
  },
  selectionItem: {
    paddingBottom: 5,
    marginTop: 0,
    width: 300,
  },
  selectionSection: {
    paddingLeft: 10,
    listStyle: "none",
    textAlign: "left",
    width: 450,
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
  },
};
