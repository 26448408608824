import axios from "axios";
import qs from "qs";

// URL Session
// const token = localStorage.getItem("token");
const config = {
  headers: {
    // Authorization: `Bearer ${token}`,
  },
};

const deleteUrlSession = (url, callback) => {
  axios
    .delete(url, config)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

const getUrlSession = (url, callback, isBaseData) => {
  isBaseData = isBaseData || false;

  axios
    .get(url, config)
    .then((response) => {
      const data = response.data;
      if (typeof data.errors !== "undefined") {
        return callback(null);
      }
      const _data = isBaseData ? data : data.data;
      return callback(_data);
    })
    .catch((error) => console.log(error));
};

const postUrlSession = (url, body, callback) => {
  axios
    .post(url, qs.stringify(body), config)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

export { deleteUrlSession, getUrlSession, postUrlSession };
