import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../components/layouts/BodyLayout";

const BusinessPage = () => {
  return (
    <div>
      <BrowserView>
        <BodyLayout>
          <Row>
            <Col>Business Page</Col>
          </Row>
        </BodyLayout>
      </BrowserView>
      <MobileView></MobileView>
    </div>
  );
};

export default BusinessPage;