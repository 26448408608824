import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SearchResultsCard = (props) => {
  const { data } = props;

  const Address = () => {
    var address = `${data.address1}`;

    if (data.address2.length > 0) {
      address = address.concat(`, ${data.address2}`);
    }

    address = address.concat(`, ${data.location}`);

    return address;
  };

  return (
    <Row style={styles.cardStyle}>
      <Col md="auto">
        <img
          src={require("../../assets/images/barber.png")}
          style={{ height: "auto", width: 80 }}
          alt="logo"
        />
      </Col>
      <Col>
        <Row>
          <Link to={`/business/${data.id}`} style={styles.linkStyle}>
            {`${props.rank}. ${data.name}`}
          </Link>
        </Row>
        <Row style={{ textAlign: "left" }}>
          <Address />
        </Row>
        <Row>{data.phone}</Row>
      </Col>
    </Row>
  );
};

export default SearchResultsCard;

const styles = {
  cardStyle: {
    backgroundColor: "white",
    minHeight: 100,
    padding: 10,
    paddingLeft: 0,
    marginBottom: 10,
  },
  linkStyle: {
    color: "#000",
    paddingLeft: 0,
    fontWeight: "bold",
    fontSize: 21,
    textAlign: "left",
    textDecoration: "none",
  },
};
