import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const BasicButton = (props) => {
  const { onClick, text, disabled } = props;

  return (
    <Button
      variant="primary"
      size="md"
      style={{ float: "right", marginLeft: 10 }}
      onClick={onClick}
      disabled={disabled}
    >
      <span style={styles.buttonTextStyle}>{text}</span>
    </Button>
  );
};

const MainButton = (props) => {
  const { to, text } = props;

  return (
    <Link to={to}>
      <Button variant="primary" size="md" style={{ float: "right" }}>
        <span style={styles.buttonTextStyle}>{text}</span>
      </Button>
    </Link>
  );
};

export { BasicButton, MainButton };

const styles = {
  buttonTextStyle: {
    fontWeight: "600",
    fontSize: "small",
  },
};
