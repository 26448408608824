import React from "react";
import { LAYOUTS } from "../../constants";

const BodyLayout = (props) => {
  return (
    <div className="container-fluid" style={LAYOUTS.bodyStyle}>
      <div style={LAYOUTS.mainSectionStyle}>{props.children}</div>
    </div>
  );
};

export default BodyLayout;
