import React from "react";
import { Routes, Route } from "react-router-dom";
// import { BrowserView, MobileView } from "react-device-detect";
import BusinessPage from "../pages/Business/BusinessPage";
import Home from "../pages/Home";
import Search from "../pages/Search";

const Application = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="business/:busid" element={<BusinessPage />} />
        <Route path="search" element={<Search />} />
      </Routes>
    </div>
  );
};

export default Application;