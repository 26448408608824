import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BodyLayout from "../components/layouts/BodyLayout";
import { getSearchInfo } from "../api/ApiManager";

const Home = () => {
  const defaultState = {
    categories: [],
    locations: [],
    selectedCategoryId: 0,
    selectedLocation: null,
    searchLocationList: [],
  };
  const [homeState, setHomeState] = useState(defaultState);
  const navigate = useNavigate();

  // Sections
  const CategorySelectMenu = () => {
    const options = homeState.categories.map((category) => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });

    return (
      <div>
        <select
          className="p-2 w-100"
          onChange={handleChange}
          value={homeState.selectedCategoryId}
          name="categorySelectMenu"
          id="categorySelectMenu"
        >
          <option value="default">Select a Category</option>
          {options}
        </select>
      </div>
    );
  };

  const LocationSelections = () => {
    const locationSelection = homeState.searchLocationList.map(
      (selection, idx) => {
        return (
          <li
            key={selection}
            name={selection}
            value={selection}
            onClick={handleClick}
            style={styles.selectionItem}
          >
            {selection}
          </li>
        );
      }
    );

    return (
      <div>
        <ul style={styles.selectionSection}>{locationSelection}</ul>
      </div>
    );
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "categorySelectMenu":
        setHomeState({
          ...homeState,
          selectedCategoryId: parseInt(e.target.value),
        });
        break;
      case "location":
        if (e.target.value === "" || typeof e.target.value === "undefined") {
          setHomeState({
            ...homeState,
            selectedLocation: null,
            searchLocationList: [],
          });
        } else {
          const locationInput = e.target.value;
          getLocationList(locationInput);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    const name = e.target.name || e.target.getAttribute("name");
    switch (name) {
      default:
        const selectedLocation = e.target.getAttribute("name");
        setHomeState({
          ...homeState,
          selectedLocation: e.target.getAttribute("value"),
          searchLocationList: [],
        });
        document.getElementById("location").value = selectedLocation;
        break;
    }
  };

  // Handle Search when the search button clicked
  const handleSearch = () => {
    const catId = homeState.selectedCategoryId;
    const location = homeState.selectedLocation;
    const url = `search?location=${location}&page=1&categoryId=${catId}&fromHome=true`;
    navigate(url);
  };

  // Methods
  const getLocationList = (query) => {
    const locations = homeState.locations
      .map((location) => {
        return location.name;
      })
      .filter((location) =>
        location.toLowerCase().startsWith(query.toLowerCase())
      );

    setHomeState({ ...homeState, searchLocationList: locations });
  };

  // Hooks
  useEffect(() => {
    getSearchInfo((info) => {
      setHomeState({
        ...homeState,
        categories: info.categories,
        locations: info.locations,
      });
    });
  }, []);

  return (
    <div>
      <BrowserView>
        <BodyLayout>
          <Row>
            <Form style={styles.form}>
              <Form.Group>
                <Row>
                  <Col md="auto">
                    <Link
                      to="/"
                      style={{
                        color: "blue",
                        fontSize: 40,
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Hydrogen
                    </Link>
                  </Col>
                  <Col md="auto" className="d-flex align-items-center">
                    <CategorySelectMenu />
                  </Col>
                  <Col md={4} style={{ marginTop: 11 }}>
                    <Row>
                      <Form.Control
                        type="text"
                        placeholder="city, state or zip"
                        id="location"
                        name="location"
                        onChange={handleChange}
                        defaultValue={homeState.selectedLocation}
                        autoComplete="off"
                      />
                    </Row>
                    <Row style={styles.locationSelectionSection}>
                      <LocationSelections />
                    </Row>
                  </Col>
                  <Col md="auto" style={{ marginTop: 11, marginLeft: -55 }}>
                    <Button onClick={handleSearch}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-magnifying-glass"
                        size="1x"
                      />
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Row>
        </BodyLayout>
      </BrowserView>
      <MobileView></MobileView>
    </div>
  );
};

export default Home;

const styles = {
  form: {
    width: "100%",
    textAlign: "left",
  },
  locationSelectionSection: {
    marginTop: -1,
    marginLeft: -20,
    height: 0,
  },
  selectionItem: {
    paddingBottom: 5,
    marginTop: 0,
    width: 300,
  },
  selectionSection: {
    paddingLeft: 10,
    listStyle: "none",
    textAlign: "left",
    width: 450,
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
  },
};
